exports.components = {
  "component---src-pages-builds-components-latest-builds-latest-builds-tsx": () => import("./../../../src/pages/builds/components/latest-builds/LatestBuilds.tsx" /* webpackChunkName: "component---src-pages-builds-components-latest-builds-latest-builds-tsx" */),
  "component---src-pages-builds-components-new-build-form-new-build-form-tsx": () => import("./../../../src/pages/builds/components/new-build-form/NewBuildForm.tsx" /* webpackChunkName: "component---src-pages-builds-components-new-build-form-new-build-form-tsx" */),
  "component---src-pages-builds-index-tsx": () => import("./../../../src/pages/builds/index.tsx" /* webpackChunkName: "component---src-pages-builds-index-tsx" */),
  "component---src-pages-builds-presets-index-tsx": () => import("./../../../src/pages/builds-presets/index.tsx" /* webpackChunkName: "component---src-pages-builds-presets-index-tsx" */),
  "component---src-pages-cohorts-[identifier]-tsx": () => import("./../../../src/pages/cohorts/[identifier].tsx" /* webpackChunkName: "component---src-pages-cohorts-[identifier]-tsx" */),
  "component---src-pages-cohorts-index-tsx": () => import("./../../../src/pages/cohorts/index.tsx" /* webpackChunkName: "component---src-pages-cohorts-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-devices-[identifier]-tsx": () => import("./../../../src/pages/devices/[identifier].tsx" /* webpackChunkName: "component---src-pages-devices-[identifier]-tsx" */),
  "component---src-pages-firmware-tsx": () => import("./../../../src/pages/firmware.tsx" /* webpackChunkName: "component---src-pages-firmware-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-releases-[identifier]-tsx": () => import("./../../../src/pages/releases/[identifier].tsx" /* webpackChunkName: "component---src-pages-releases-[identifier]-tsx" */),
  "component---src-pages-sign-apk-index-tsx": () => import("./../../../src/pages/sign-apk/index.tsx" /* webpackChunkName: "component---src-pages-sign-apk-index-tsx" */)
}

